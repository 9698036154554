import { useIntervalEffect } from "@react-hookz/web";
import md5 from "md5";
import { useEffect, useState } from "react";
import useAuthentication from "~/hooks/useAuthentication";

type State = {
  didChatwootLoad: boolean;
};

export default function ChatwootWidget() {
  const { user } = useAuthentication();
  const [state, setState] = useState<State>({ didChatwootLoad: false });

  useIntervalEffect(() => {
    if (window.$chatwoot !== undefined && window.$chatwoot.hasLoaded) {
      setState((s) => ({ ...s, didChatwootLoad: true }));
    }
  }, state.didChatwootLoad ? undefined : 500);

  useEffect(() => {
    if (state.didChatwootLoad && window.$chatwoot) {
      if (user) {
        window.$chatwoot.setUser(user.email, {
          avatar_url: `https://www.gravatar.com/avatar/${md5(user.email)}?d=mysteryman&s=512`,
          company_name: user.organization.name,
          email: user.email,
          identifier_hash: user.chatwootIdentifier,
          name: user.name,
        });
      }
    }
  }, [state.didChatwootLoad, user]);

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
            (function(d,t) {
              var BASE_URL="https://chat.webgazer.io";
              var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
              g.src=BASE_URL+"/packs/js/sdk.js";
              g.defer = true;
              g.async = true;
              s.parentNode.insertBefore(g,s);
              g.onload=function(){
                window.chatwootSDK.run({
                  websiteToken: 'V9maqG8EZsh2ddi5VNwjzcaB',
                  baseUrl: BASE_URL
                })
              }
            })(document,"script");
          `,
      }}
    />
  );
}
