import posthog, { PostHog } from "posthog-js";
import { PostHogProvider as Provider } from "posthog-js/react";
import { ReactNode, useMemo } from "react";

export type PostHogProviderProps = {
  children: ReactNode;
};

export default function PostHogProvider({ children }: PostHogProviderProps) {
  const postHogClient = useMemo<PostHog | undefined>(() => {
    if (
      typeof window === "undefined"
      || import.meta.env.VITE_POSTHOG_API_KEY === undefined
    ) {
      return undefined;
    }

    posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, { api_host: "/ingest" });

    return posthog;
  }, []);

  return (
    <Provider client={postHogClient}>
      {children}
    </Provider>
  );
}
