import { Helmet } from "react-helmet-async";

export default function RewardfulSnippet() {
  return (
    <Helmet>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,r){w._rwq = r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
        }}
      />
      <script async data-rewardful="55a2f0" src="https://r.wdfl.co/rw.js"></script>
    </Helmet>
  );
}
