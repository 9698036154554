import { createContext, ReactNode } from "react";

export type ToastsContextToast = {
  body: ReactNode;
  key: string;
  variant: "danger" | "success";
};

export type ToastsContextValue = {
  addToast: (toast: PartialBy<ToastsContextToast, "key">) => void;
  deleteToast: (key: ToastsContextToast["key"]) => void;
};

export default createContext<ToastsContextValue>({
  addToast: (_) => {},
  deleteToast: (_) => {},
});
