import * as Sentry from "@sentry/react";
import { replayIntegration } from "@sentry/react";
import { enGB } from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import "dayjs/locale/en-gb.js";
import dayjsDuration from "dayjs/plugin/duration";
import dayjsIsoWeek from "dayjs/plugin/isoWeek";
import dayjsLocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import dayjsUtc from "dayjs/plugin/utc";
import { domAnimation, LazyMotion } from "framer-motion";
import { StrictMode, useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { createRoutesFromChildren, matchRoutes, Outlet, ScrollRestoration, useLocation, useNavigationType } from "react-router-dom";
import "~/styles/style.scss";
import AppErrorBoundary from "~/components/AppErrorBoundary";
import AuthenticationProvider from "~/components/AuthenticationProvider";
import ColorModeProvider from "~/components/ColorModeProvider";
import PlausibleSnippet from "~/components/PlausibleSnippet";
import PostHogHandler from "~/components/PostHogHandler";
import PostHogProvider from "~/components/PostHogProvider";
import SentryHandler from "~/components/SentryHandler";
import SwrConfig from "~/components/SwrConfig";
import Tags from "~/components/Tags";
import ToastsProvider from "~/components/ToastsProvider";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect,
        useLocation,
        useNavigationType,
      }),
      replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
      }),
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    tunnel: "/ingest-errors",
  });
}

dayjs.extend(dayjsDuration);
dayjs.extend(dayjsIsoWeek);
dayjs.extend(dayjsLocalizedFormat);
dayjs.extend(dayjsRelativeTime);
dayjs.extend(dayjsUtc);

dayjs.locale("en-gb");

registerLocale("en-GB", enGB);
setDefaultLocale("en-GB");

export default function App() {
  return (
    <StrictMode>
      <AppErrorBoundary>
        <PostHogProvider>
          <ScrollRestoration />

          <LazyMotion features={domAnimation}>
            <SwrConfig>
              <AuthenticationProvider>
                <Tags />
                <PlausibleSnippet />
                <PostHogHandler />
                <SentryHandler />

                <ColorModeProvider>
                  <ToastsProvider>
                    <Outlet />
                  </ToastsProvider>
                </ColorModeProvider>
              </AuthenticationProvider>
            </SwrConfig>
          </LazyMotion>
        </PostHogProvider>
      </AppErrorBoundary>
    </StrictMode>
  );
}
