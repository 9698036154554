import { createContext } from "react";

type User = HydratedAuthenticationUser | null;

export type AuthenticationContextValue = {
  canWrite: boolean;
  isNavigationInProgress: boolean;
  isOrganizationOwner: boolean;
  isValidating: boolean;
  refresh: () => Promise<void>;
  setIsNavigationInProgress: (isNavigationInProgress: boolean) => void;
  signOut: (forReal?: boolean) => void;
  user?: User;
};

export default createContext<AuthenticationContextValue>({
  canWrite: false,
  isNavigationInProgress: false,
  isOrganizationOwner: false,
  isValidating: false,
  refresh: () => Promise.resolve(undefined),
  setIsNavigationInProgress: () => {},
  signOut: () => {},
  user: null,
});
