import { Parser } from "html-to-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

type State = {
  tags: Tags | null;
};

type Tags = {
  body: string;
  head: string;
};

const parser = Parser();

export default function Tags() {
  const [state, setState] = useState<State>({ tags: null });

  useEffect(() => {
    async function run() {
      let url = "https://webhooks.webgazer.io/webgazer-tags";

      if (import.meta.env.VITE_TAGS_ENVIRONMENT !== undefined) {
        url += `?environment=${import.meta.env.VITE_TAGS_ENVIRONMENT}`;
      }

      const response = await fetch(url);
      const responseJson: Tags = await response.json();

      setState((s) => ({ ...s, tags: responseJson }));
    }

    run();
  }, []);

  return (
    <>
      <Helmet>
        {state.tags !== null && state.tags.head !== "" ? parser.parse(state.tags.head) : null}
      </Helmet>

      {state.tags !== null && state.tags.body !== "" ? parser.parse(state.tags.body) : null}
    </>
  );
}
