import { useEffect, useMemo, useRef } from "react";
import { Outlet, To, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuthentication from "~/hooks/useAuthentication";
import useToasts from "~/hooks/useToasts";

export type WithAuthorizationParams = {
  isAuthenticated: false;
} | {
  isAuthenticated: true;
  redirectTo?: To;
  roles?: Array<UserRole>;
};

export default function withAuthorization(params: WithAuthorizationParams) {
  function Wrapped() {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [searchParams] = useSearchParams();
    const { isNavigationInProgress, user } = useAuthentication();
    const { addToast } = useToasts();
    const isRedirecting = useRef<boolean>(false);
    const isPermitted = useMemo<boolean | null>(() => {
      if (user === undefined) {
        return null;
      }

      if (params.isAuthenticated) {
        return user !== null && (params.roles === undefined || params.roles.includes(user.role));
      }

      return user === null;
    }, [user]);

    useEffect(() => {
      if (!isNavigationInProgress && !isRedirecting.current && isPermitted === false) {
        isRedirecting.current = true;

        let to: To = params.isAuthenticated ? `/sign-in?next=${pathname}${search}` : "/monitors";
        let toastBody = params.isAuthenticated ? "You need to sign in to see this page." : "You are not authorized to see this page.";

        if (params.isAuthenticated) {
          if (user !== null && params.redirectTo !== undefined) {
            to = params.redirectTo;
          }
        } else {
          to = searchParams.get("next") || "/monitors";
        }

        addToast({ body: toastBody, variant: "danger" });
        navigate(to, { replace: true });
      }
    }, [addToast, isNavigationInProgress, isPermitted, navigate, pathname, search, searchParams, user]);

    return isPermitted ? (
      <Outlet />
    ) : (
      <div className="align-items-center bg-body bottom-0 d-flex end-0 flex-column justify-content-center p-4 position-fixed top-0 start-0">
        <div className="spinner-border text-primary" />
      </div>
    );
  }

  return Wrapped;
}
