import { useMemo } from "react";
import { useMatches } from "react-router-dom";

function hasHeaderVariant(match: unknown): match is { handle: { headerVariant: "application" | "site" } } {
  return typeof match === "object"
    && match !== null
    && "handle" in match
    && typeof match.handle === "object"
    && match.handle !== null
    && "headerVariant" in match.handle;
}

export default function useHeaderVariant(): "application" | "site" {
  const matches = useMatches();

  return useMemo<"application" | "site">(() => {
    for (const match of matches) {
      if (hasHeaderVariant(match)) {
        return match.handle.headerVariant;
      }
    }

    throw Error("No header variant found");
  }, [matches]);
}
