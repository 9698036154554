import { ReactNode } from "react";
import { SWRConfig } from "swr";
import { getFetcher } from "~/helpers/api";

export type SwrConfigProps = {
  children: ReactNode;
};

const fetcher = getFetcher(false);

export default function SwrConfig({ children }: SwrConfigProps) {
  return (
    <SWRConfig value={{ fetcher }}>
      {children}
    </SWRConfig>
  );
}
