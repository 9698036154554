const authenticationUserOrganizationSubscriptionPeriodDisplays: Record<
  AuthenticationUserOrganizationSubscriptionPeriod,
  AuthenticationUserOrganizationSubscriptionPeriodDisplay
> = {
  MONTH: "Month",
  YEAR: "Year",
};

function hydrateAuthenticationUserOrganization(o: AuthenticationUserOrganization): HydratedAuthenticationUserOrganization {
  return {
    ...o,
    subscriptionPeriodDisplay: o.subscriptionPeriod === null
      ? null
      : authenticationUserOrganizationSubscriptionPeriodDisplays[o.subscriptionPeriod],
  };
}

export function hydrateAuthenticationUser(u: AuthenticationUser): HydratedAuthenticationUser {
  return {
    ...u,
    organization: hydrateAuthenticationUserOrganization(u.organization),
  };
}
