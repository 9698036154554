import { useEffect, useMemo } from "react";
import { Outlet, To, useNavigate, useSearchParams } from "react-router-dom";
import useToasts from "~/hooks/useToasts";

export type WithRequiredSearchParamParams = {
  check?: (rawValue: string | null) => boolean;
  redirectTo: To;
  searchParamName: string;
};

export default function withRequiredSearchParam(params: WithRequiredSearchParamParams) {
  function Wrapped() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { addToast } = useToasts();

    const isPermitted = useMemo<boolean>(() => {
      const rawValue = searchParams.get(params.searchParamName);

      if (rawValue === null || rawValue === "") {
        return false;
      }

      if (params.check !== undefined) {
        return params.check(rawValue);
      }

      return true;
    }, [searchParams]);

    useEffect(() => {
      if (!isPermitted) {
        addToast({ body: "Not found.", key: `required-search-param-missing-or-invalid-${params.searchParamName}`, variant: "danger" });
        navigate(params.redirectTo, { replace: true });
      }
    }, [addToast, isPermitted, navigate]);

    return isPermitted ? (
      <Outlet />
    ) : (
      <div className="align-items-center bg-body bottom-0 d-flex end-0 flex-column justify-content-center p-4 position-fixed top-0 start-0">
        <div className="spinner-border text-primary" />
      </div>
    );
  }

  return Wrapped;
}
