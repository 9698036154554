import { useMemo } from "react";
import useSWR, { BareFetcher, SWRConfiguration, SWRResponse } from "swr";
import { getFetcher } from "~/helpers/api";
import { hydrateAuthenticationUser } from "~/helpers/api/authentication";

type Config = SWRConfiguration<Data, Error, BareFetcher<Data>>;
type Data = AuthenticationUser | null | undefined;
type HydratedData = HydratedAuthenticationUser | null | undefined;
type Response = Overwrite<SWRResponse<Data, Error>, {
  data: HydratedData | undefined;
}>;

const fetcher = getFetcher(true);

export default function useAuthenticationUser(config?: Config): Response {
  const { data: rawData, ...swrResponse } = useSWR<Data, Error>("/authentication/me", fetcher, config);

  const data = useMemo<Response["data"]>(() => {
    if (!rawData) {
      return rawData;
    }

    return hydrateAuthenticationUser(rawData);
  }, [rawData]);

  return { data, ...swrResponse };
}
