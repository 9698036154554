import { uniqueId } from "lodash-es";
import { ReactNode, useCallback, useState } from "react";
import { ToastContainer } from "react-bootstrap";
import Portal from "~/components/Portal";
import ToastsContext, { ToastsContextToast, ToastsContextValue } from "~/contexts/ToastsContext";
import Toast from "./Toast";

export type ToastsProviderProps = {
  children: ReactNode;
};

type State = {
  toasts: Array<ToastsContextToast>;
};

export default function ToastsProvider({ children }: ToastsProviderProps) {
  const [state, setState] = useState<State>({ toasts: [] });

  const addToast = useCallback<ToastsContextValue["addToast"]>((toast) => {
    setState((s) => (
      {
        ...s,
        toasts: toast.key === undefined || s.toasts.find((d) => d.key === toast.key) === undefined ? [
          ...s.toasts, {
            ...toast,
            key: toast.key || uniqueId("toast-"),
          },
        ] : s.toasts,
      }
    ));
  }, []);

  const deleteToast = useCallback<ToastsContextValue["deleteToast"]>((key) => {
    setState((s) => ({ ...s, toasts: s.toasts.filter((t) => t.key !== key) }));
  }, []);

  return (
    <ToastsContext.Provider value={{ addToast, deleteToast }}>
      {children}

      <Portal>
        <ToastContainer className="p-4 pt-13" containerPosition="fixed" position="top-center">
          {state.toasts.map((toast) => (
            <Toast key={toast.key} onExited={() => deleteToast(toast.key)} toast={toast} />
          ))}
        </ToastContainer>
      </Portal>
    </ToastsContext.Provider>
  );
}
