import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import useAuthentication from "~/hooks/useAuthentication";

export default function PlausibleSnippet() {
  const { user } = useAuthentication();

  const scriptAttrs = useMemo(() => {
    if (user) {
      return {
        "event-organization_id": user.organization.id,
        "event-user_email": user.email,
        "event-user_id": user.id,
        "event-user_role": user.role,
      };
    }

    return {};
  }, [user]);

  return (
    <Helmet>
      <script
        data-domain={import.meta.env.VITE_BASE_URL.split("://")[1]}
        defer
        src="/js/script.pageview-props.js"
        {...scriptAttrs}
      ></script>
    </Helmet>
  );
}
