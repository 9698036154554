import { useMemo } from "react";
import { Helmet } from "react-helmet-async";

export type TitleProps = {
  children: string;
  template?: "blog" | "default" | "docs" | "statusPage";
};

export default function Title({ children, template = "default" }: TitleProps) {
  const title = useMemo(() => {
    return ({
      blog: `${children} | WebGazer Blog`,
      default: `${children} | WebGazer`,
      docs: `${children} | WebGazer Docs`,
      statusPage: `${children}`,
    })[template];
  }, [children, template]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta content={title} property="og:title" />
    </Helmet>
  );
}
