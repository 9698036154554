import { useLocation } from "react-router-dom";
import Footer from "~/components/Footer";
import Title from "~/components/Title";
import ImageError from "./error.svg";

export default function Error() {
  const location = useLocation();

  return (
    <>
      <Title>An error has occurred</Title>

      {/*<Header />*/}

      <main className="align-items-center d-flex flex-grow-1">
        <div className="container py-16">
          <div className="align-items-center row row-cols-1 row-cols-md-2">
            <div className="col">
              <img alt="error" className="d-block mw-100 w-32rem" src={ImageError} />
            </div>

            <div className="col fs-5">
              <h1>An error has occurred.</h1>

              <p className="mt-8">
                We apologize for the inconvenience. Our team has been notified, and we&apos;re working to fix the issue. Please try again
                later.
              </p>

              <p>
                If the problem persists, please
                {" "}
                <a href={`mailto:support@webgazer.io?subject=I%20encounter%20an%20error%20in%20${location.pathname}`}>contact support</a>
                {" "}
                for assistance.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}
