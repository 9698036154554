import { usePostHog } from "posthog-js/react";
import { useEffect, useRef } from "react";
import useAuthentication from "~/hooks/useAuthentication";

export default function PostHogHandler() {
  const posthog = usePostHog();
  const { user } = useAuthentication();
  const previousUser = useRef<HydratedAuthenticationUser | null>(null);

  useEffect(() => {
    if (posthog.__loaded && user !== undefined && user !== previousUser.current) {
      if (user) {
        posthog.identify(user.id.toString(), {
          email: user.email,
          name: user.name,
          role: user.role,
        });

        posthog.group("organization", user.organization.id.toString(), {
          isOnTrial: user.organization.isOnTrial,
          name: user.organization.name,
          planName: user.organization.plan.name,
        });
      } else {
        posthog.reset();
      }

      previousUser.current = user;
    }
  }, [posthog, user]);

  return null;
}
