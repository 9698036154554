import { Outlet } from "react-router-dom";
import Footer from "../Footer";

export default function SiteLayout() {
  return (
    <>
      <main className="d-flex flex-column flex-grow-1">
        <Outlet />
      </main>

      <Footer />
    </>
  );
}
