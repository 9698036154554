import { Helmet } from "react-helmet-async";

export type OgImageProps = {
  image: {
    height: number;
    url: string;
    width: number;
  };
};

export default function OgImage({ image }: OgImageProps) {
  return (
    <Helmet>
      <meta content={`${import.meta.env.VITE_BASE_URL}${image.url}`} property="og:image" />
      <meta content={image.height.toString()} property="og:image:height" />
      <meta content={image.width.toString()} property="og:image:width" />
      <meta content={`${import.meta.env.VITE_BASE_URL}${image.url}`} name="twitter:image" />
    </Helmet>
  );
}
