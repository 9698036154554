import classNames from "classnames";
import { JSX, PropsWithoutRef, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./StatusIndicator.module.scss";

export type StatusIndicatorProps = Overwrite<Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "children">, {
  pulsate?: boolean;
  status?: string;
}>;

export default function StatusIndicator({ className, pulsate, status, ...props }: StatusIndicatorProps) {
  const content = useMemo(() => {
    return (
      <div
        {...props}
        className={classNames(
          "d-inline-block rounded-circle size-0_625rem",
          styles.circle,
          { [styles.pulsate]: pulsate },
          className,
        )}
      />
    );
  }, [className, props, pulsate]);

  return status === undefined ? content : (
    <OverlayTrigger
      overlay={(
        <Tooltip className="fw-semi-bold" key={status}>{status}</Tooltip>
      )}
      placement="bottom"
    >
      {content}
    </OverlayTrigger>
  );
}
