import classNames from "classnames";
import { pick } from "lodash-es";
import { useCallback, useState } from "react";
import { Modal as BsModal } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import Portal from "~/components/Portal";
import { api } from "~/helpers/api";
import { setErrors } from "~/helpers/form";

type State = {
  isModalDismissed: boolean;
};

type Form = {
  fields: Array<AuthenticationMissingInformationField>;
  options: Record<AuthenticationMissingInformationField, Array<string>>;
  organizationAcquisitionChannel: string;
  organizationIndustry: string;
  organizationSize: string;
  userPosition: string;
};

const defaultOptions: Form["options"] = {
  organizationAcquisitionChannel: [],
  organizationIndustry: [],
  organizationSize: [],
  userPosition: [],
};

export default function Modal() {
  const { showBoundary } = useErrorBoundary();
  const { formState: { errors }, handleSubmit, register, setError, watch } = useForm<Form>({
    defaultValues: async () => {
      let defaultValues: Form = {
        fields: [],
        options: defaultOptions,
        organizationAcquisitionChannel: "",
        organizationIndustry: "",
        organizationSize: "",
        userPosition: "",
      };

      try {
        const response = await api.get("/authentication/missing-information");
        const responseJson = await response.json();

        defaultValues.fields = responseJson.fields;
        defaultValues.options = responseJson.options;
      } catch (e) {
        showBoundary(e);
      }

      return defaultValues;
    },
  });
  const [state, setState] = useState<State>({ isModalDismissed: false });
  const fields = watch("fields", []);
  const options = watch("options", defaultOptions);
  const organizationIndustry = watch("organizationIndustry", "");
  const organizationAcquisitionChannel = watch("organizationAcquisitionChannel", "");
  const organizationSize = watch("organizationSize", "");
  const userPosition = watch("userPosition", "");

  const dismissModal = useCallback(() => setState((s) => ({ ...s, isModalDismissed: true })), []);

  async function submit(data: Form) {
    const response = await api.post("/authentication/missing-information", pick(data, fields));
    const responseJson = await response.json();

    if (response.ok) {
      dismissModal();
    } else {
      setErrors(setError, responseJson);
    }
  }

  return (
    <Portal>
      <BsModal centered className="z-1056" onHide={dismissModal} show={fields.length > 0 && !state.isModalDismissed}>
        <form onSubmit={handleSubmit(submit)}>
          <fieldset>
            <BsModal.Header closeButton>
              <BsModal.Title>One last thing before you continue...</BsModal.Title>
            </BsModal.Header>

            <BsModal.Body>
              <div className="gap-12 vstack">
                {fields.includes("userPosition") ? (
                  <div>
                    <label className="form-label" htmlFor="input-missing-information-user-position">
                      Which of the following best describes your position?
                    </label>

                    <select
                      className={classNames("form-select", { "is-invalid": errors.userPosition })}
                      id="input-missing-information-user-position"
                      required
                      {...register("userPosition")}
                    >
                      <option disabled={userPosition !== ""} value="" />

                      {options.userPosition.map((d) => (
                        <option key={d} value={d}>{d}</option>
                      ))}
                    </select>

                    <div className="invalid-feedback">{errors.userPosition?.message}</div>
                  </div>
                ) : null}

                {fields.includes("organizationIndustry") ? (
                  <div>
                    <label className="form-label" htmlFor="input-missing-information-organization-industry">
                      Which of the following best describes your industry?
                    </label>

                    <select
                      className={classNames("form-select", { "is-invalid": errors.organizationIndustry })}
                      id="input-missing-information-organization-industry"
                      required
                      {...register("organizationIndustry")}
                    >
                      <option disabled={organizationIndustry !== ""} value="" />

                      {options.organizationIndustry.map((d) => (
                        <option key={d} value={d}>{d}</option>
                      ))}
                    </select>

                    <div className="invalid-feedback">{errors.organizationIndustry?.message}</div>
                  </div>
                ) : null}

                {fields.includes("organizationAcquisitionChannel") ? (
                  <div>
                    <label className="form-label" htmlFor="input-missing-information-organization-acquisition-channel">
                      How did you hear about WebGazer?
                    </label>

                    <select
                      className={classNames("form-select", { "is-invalid": errors.organizationAcquisitionChannel })}
                      id="input-missing-information-organization-acquisition-channel"
                      required
                      {...register("organizationAcquisitionChannel")}
                    >
                      <option disabled={organizationAcquisitionChannel !== ""} value="" />

                      {options.organizationAcquisitionChannel.map((d) => (
                        <option key={d} value={d}>{d}</option>
                      ))}
                    </select>

                    <div className="invalid-feedback">{errors.organizationAcquisitionChannel?.message}</div>
                  </div>
                ) : null}

                {fields.includes("organizationSize") ? (
                  <div>
                    <label className="form-label" htmlFor="input-missing-information-organization-size">
                      Please select the size range that best describes your company.
                    </label>

                    <select
                      className={classNames("form-select", { "is-invalid": errors.organizationSize })}
                      id="input-missing-information-organization-size"
                      required
                      {...register("organizationSize")}
                    >
                      <option disabled={organizationSize !== ""} value="" />

                      {options.organizationSize.map((d) => (
                        <option key={d} value={d}>{d}</option>
                      ))}
                    </select>

                    <div className="invalid-feedback">{errors.organizationSize?.message}</div>
                  </div>
                ) : null}
              </div>
            </BsModal.Body>

            <BsModal.Footer>
              <button className="btn btn-primary" type="submit">Continue</button>
            </BsModal.Footer>
          </fieldset>
        </form>
      </BsModal>
    </Portal>
  );
}
