import { Helmet } from "react-helmet-async";

export default function MetaTags() {
  return (
    <Helmet>
      <meta content="summary" name="twitter:card"  />
      <meta content="@hgokhansari" name="twitter:creator" />
      <meta content="@WebGazerIO" name="twitter:site" />
    </Helmet>
  );
}
