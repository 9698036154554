import useAuthentication from "~/hooks/useAuthentication";
import Modal from "./Modal";

export default function MissingInformationModal() {
  const { user } = useAuthentication();

  return user !== null && user !== undefined ? (
    <Modal />
  ) : null;
}
