import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CloseButton, CloseButtonProps, Toast as BsToast, ToastProps as BsToastProps } from "react-bootstrap";
import { ToastsContextToast } from "~/contexts/ToastsContext";

export type ToastProps = {
  onExited: () => void;
  toast: ToastsContextToast;
};

type State = {
  autoHide: boolean;
  isShown: boolean;
};

const toastBgs: Record<ToastsContextToast["variant"], BsToastProps["bg"]> = {
  danger: "danger",
  success: "success",
};

const bodyClassNames: Record<ToastsContextToast["variant"], string | undefined> = {
  danger: "text-white",
  success: "text-white",
};

const closeButtonVariants: Record<ToastsContextToast["variant"], CloseButtonProps["variant"]> = {
  danger: "white",
  success: "white",
};

export default function Toast({ onExited, toast }: ToastProps) {
  const [state, setState] = useState<State>({ autoHide: true, isShown: false });

  const bg = useMemo(() => toastBgs[toast.variant], [toast.variant]);
  const bodyClassName = useMemo(() => bodyClassNames[toast.variant], [toast.variant]);
  const closeButtonVariant = useMemo(() => closeButtonVariants[toast.variant], [toast.variant]);

  const handleClose = useCallback(() => {
    setState((s) => ({ ...s, isShown: false }));
  }, []);

  useEffect(() => {
    setState((s) => ({ ...s, isShown: true }));
  }, []);

  return (
    <BsToast
      autohide={state.autoHide}
      bg={bg}
      className="border-0"
      onClose={handleClose}
      onExited={onExited}
      onMouseEnter={() => setState((s) => ({ ...s, autoHide: false }))}
      onMouseLeave={() => setState((s) => ({ ...s, autoHide: true }))}
      show={state.isShown}
    >
      <BsToast.Body className={classNames("d-flex flex-row gap-3", bodyClassName)}>
        <div className="flex-grow-1 me-auto fw-medium">{toast.body}</div>

        <CloseButton className="flex-shrink-0" onClick={handleClose} variant={closeButtonVariant} />
      </BsToast.Body>
    </BsToast>
  );
}
