import * as Sentry from "@sentry/react";
import { useEffect, useRef } from "react";
import useAuthentication from "~/hooks/useAuthentication";

export default function SentryHandler() {
  const { user } = useAuthentication();
  const previousUser = useRef<HydratedAuthenticationUser | null>(null);

  useEffect(() => {
    if (user !== undefined && user !== previousUser.current) {
      if (user) {
        Sentry.setUser({ email: user.email });
      } else {
        Sentry.setUser(null);
      }

      previousUser.current = user;
    }
  }, [user]);

  return null;
}
